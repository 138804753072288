<template>
  <div class="base-table">
    <el-table v-bind="$attrs" :data="pageData" :header-cell-style="tableHeader">
      <!--选择框-->
      <el-table-column
        v-if="hasChecked"
        align="center"
        type="selection"
        width="60"
      />
      <!--序号-->
      <el-table-column
        v-if="false"
        label="序号"
        type="index"
        width="80"
        align="center"
      />

      <!--基本配置项-->
      <template v-for="(item, index) in tableOptions" :key="index">
        <el-table-column
          v-bind="item.attrs"
          :label="item.label"
          :prop="item.prop"
          show-overflow-tooltip
        >
          <template #header v-if="item.customHeader"></template>
          <template #default="scope">
            <slot :name="item.slot" :row="scope.row">
              {{ scope.row[item.prop] }}
            </slot>
          </template>
        </el-table-column>
      </template>
      <!--操作项-->
      <el-table-column
        v-if="actionOptions?.action"
        fixed="right"
        :label="actionOptions.label"
        v-bind="actionOptions.attrs"
      >
        <template #default="scope">
          <slot name="action" :row="scope.row"></slot>
        </template>
      </el-table-column>
    </el-table>

    <div class="footer-pagination my-10">
      <el-pagination
        background
        :page-size="10"
        layout="prev, pager, next"
        :total="total"
        small
        @current-change="(val) => (currentPage = val)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAttrs, computed, ref } from 'vue'
import { sortBy } from 'lodash-es'

import { tableHeader } from './config/index'

interface Props {
  tableData: Array<object>
  total: number
  hasChecked?: boolean
}
const props = defineProps<Props>()
// 配置表格的基本属性
const attrs: any = useAttrs()
const { options } = attrs

//过滤出表格的options,不是操作项的那一些
const tableOptions = computed(() =>
  options.filter((item: { action: any }) => !item.action)
)
//过滤出表格中操作项为true的哪一项
const actionOptions = computed(() =>
  options.find((item: { action: any }) => item.action)
)
// 分页处理
// 返回排序之后的表格数据
const pageSize = ref<number>(10) //默认显示的条数
const currentPage = ref<number>(1) //默认页码
const computedSortTableData = computed(() =>
  sortBy(props.tableData, (item: Record<string, any>) => -item.affinity)
)
const pageData = computed(() =>
  computedSortTableData.value.slice(
    (currentPage.value - 1) * pageSize.value,
    pageSize.value * currentPage.value
  )
)
</script>

<script lang="ts">
export default {
  name: 'BaseTable',
}
</script>

<style lang="scss" scoped>
.base-table {
  :deep(.cell) {
    height: auto !important;
  }
}

.footer-pagination {
  display: flex;
  justify-content: flex-end;
}
</style>
